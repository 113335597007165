import { useState } from 'react';
import { type Pagination } from '@/utils/filters';

export function usePagination(page = 1, pageSize = 20) {
  const [pagination, setPagination] = useState<Pagination>({ page, pageSize });

  const setPage = (newPage: number) => {
    setPagination((p) => ({ ...p, page: newPage }));
  };

  const setPageSize = (newPageSize: number) => {
    setPagination((p) => ({ ...p, pageSize: newPageSize }));
  };

  return [pagination, setPagination, { setPage, setPageSize }] as const;
}
