import { useState } from 'react';
import { SORT_ORDER } from '@/constants/sortOrder';
import type { Sorting, SortOrder } from '@witmetrics/api-client';

const { ASCENDING, DESCENDING } = SORT_ORDER;

export function useSorting(
  initialSortBy = 'createdAt',
  initialSortOrder: SortOrder = DESCENDING
) {
  const [sorting, setSorting] = useState({
    sortBy: initialSortBy,
    sortOrder: initialSortOrder,
  });

  const updateSorting = ({ sortBy, sortOrder }: Partial<Sorting>) => {
    setSorting({
      sortBy: sortBy || sorting.sortBy,
      sortOrder: sortOrder || sorting.sortOrder,
    });
  };

  const toggleSortOrder = () => {
    setSorting((sorting) => ({
      ...sorting,
      sortOrder: sorting.sortOrder === ASCENDING ? DESCENDING : ASCENDING,
    }));
  };

  const toggleSortBy = (sortBy: string) => {
    if (sorting.sortBy === sortBy) {
      setSorting({
        ...sorting,
        sortOrder: sorting.sortOrder === DESCENDING ? ASCENDING : DESCENDING,
      });
    } else {
      setSorting({ sortBy, sortOrder: DESCENDING });
    }
  };

  return [sorting, updateSorting, { toggleSortOrder, toggleSortBy }] as const;
}
