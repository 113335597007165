import { type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@/icons/DownloadIcon';
import ShareIcon from '@/icons/ShareIcon';
import EditIcon from '@/icons/EditIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import CloseIcon from '@/icons/CloseIcon';
import OptionButton from './OptionButton';
import { classes } from './utils';

export type ViewerHeaderProps = {
  label: string;
  renderIcon: (className: string) => ReactNode;
  onDownload?: () => void;
  onShare?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onClose: () => void;
};

export default function ViewerHeader({
  label,
  renderIcon,
  onDownload,
  onShare,
  onEdit,
  onDelete,
  onClose,
}: ViewerHeaderProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.labelWrapper}>
          {renderIcon(classes.typeIcon)}
          <div className={classes.label}>{label}</div>
        </div>
        <div className={classes.optionsWrapper}>
          {onDownload && (
            <OptionButton title="Download" onClick={onDownload}>
              <DownloadIcon className={classes.icon} />
            </OptionButton>
          )}
          {onShare && (
            <OptionButton title="Share" onClick={onShare}>
              <ShareIcon className={classes.icon} />
            </OptionButton>
          )}
          {onEdit && (
            <OptionButton title="Edit" onClick={onEdit}>
              <EditIcon className={classes.icon} />
            </OptionButton>
          )}
          {onDelete && (
            <OptionButton title="Delete" onClick={onDelete}>
              <DeleteIcon className={twMerge(classes.icon, classes.delete)} />
            </OptionButton>
          )}
          <Divider flexItem orientation="vertical" variant="middle" />
          <OptionButton onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </OptionButton>
        </div>
      </div>
    </div>
  );
}
