import {
  Day,
  differenceInMinutes as dateDifferenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isPast as dateIsPast,
  isSameWeek as dateIsSameWeek,
  isToday as dateIsToday,
  isTomorrow as dateIsTomorrow,
  isYesterday as dateIsYesterday,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { padNumber } from './numbers';
import { MONTHS, MONTHS_SHORT } from '@/constants/time';
import { DDMM, MMDD } from '@/constants/dateFormatPreference';

export function buildYearsArray(start: number, end: number, descending = true) {
  let result = [];
  const minYear = Math.min(start, end);
  const maxYear = Math.max(start, end);
  for (let i = maxYear; i >= minYear; i--) {
    result.push(i);
  }
  return descending ? result : result.reverse();
}

export function buildMonthsArray() {
  return [
    { longLabel: MONTHS[0], shortLabel: MONTHS_SHORT[0], value: 0 },
    { longLabel: MONTHS[1], shortLabel: MONTHS_SHORT[1], value: 1 },
    { longLabel: MONTHS[2], shortLabel: MONTHS_SHORT[2], value: 2 },
    { longLabel: MONTHS[3], shortLabel: MONTHS_SHORT[3], value: 3 },
    { longLabel: MONTHS[4], shortLabel: MONTHS_SHORT[4], value: 4 },
    { longLabel: MONTHS[5], shortLabel: MONTHS_SHORT[5], value: 5 },
    { longLabel: MONTHS[6], shortLabel: MONTHS_SHORT[6], value: 6 },
    { longLabel: MONTHS[7], shortLabel: MONTHS_SHORT[7], value: 7 },
    { longLabel: MONTHS[8], shortLabel: MONTHS_SHORT[8], value: 8 },
    { longLabel: MONTHS[9], shortLabel: MONTHS_SHORT[9], value: 9 },
    { longLabel: MONTHS[10], shortLabel: MONTHS_SHORT[10], value: 10 },
    { longLabel: MONTHS[11], shortLabel: MONTHS_SHORT[11], value: 11 },
  ];
}

export function buildHoursArray(is24Hour = false) {
  let start = is24Hour ? 0 : 1;
  let end = is24Hour ? 24 : 13;
  let result = [];
  for (let i = start; i < end; i++) {
    result.push({ value: i, label: padNumber(i) });
  }
  return result;
}

export function buildMinutesArray(interval = 1) {
  let result = [];
  for (let i = 0; i <= 59; i += interval) {
    result.push({ value: i, label: padNumber(i) });
  }
  return result;
}

export function toISOString(dateObj: Date) {
  return new Date(dateObj.valueOf() - dateObj.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1);
}

export const isToday = (ISODateString: string) =>
  dateIsToday(parseISO(ISODateString));

export const isTomorrow = (ISODateString: string) =>
  dateIsTomorrow(parseISO(ISODateString));

export const isYesterday = (ISODateString: string) =>
  dateIsYesterday(parseISO(ISODateString));

export const isThisWeek = (ISODateString: string, weekStartsOn: Day = 0) =>
  dateIsSameWeek(parseISO(ISODateString), new Date(), { weekStartsOn });

export const isPast = (ISODateString: string) =>
  dateIsPast(parseISO(ISODateString));

export function renderDate(ISODateString: string, dateFormat = MMDD) {
  const formatStr = dateFormat === DDMM ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  return format(parseISO(ISODateString), formatStr);
}

export function renderTextDate(ISODateString: string, dateFormat = MMDD) {
  const formatStr = dateFormat === DDMM ? 'dd MMM yyyy' : 'MMM dd yyyy';
  return format(parseISO(ISODateString), formatStr);
}

export function renderDateWithTime(ISODateString: string, dateFormat = MMDD) {
  const formatStr = `${
    dateFormat === DDMM ? 'dd MMM yyyy' : 'MMM dd yyyy'
  }, hh:mma`;
  return format(parseISO(ISODateString), formatStr);
}

export function renderDateOnlyTime(ISODateString: string) {
  return format(parseISO(ISODateString), 'hh:mma');
}

export function renderDayOfWeek(ISODateString: string, fullName = true) {
  return format(parseISO(ISODateString), fullName ? 'iiii' : 'iii');
}

export function renderRelativeDate(
  ISODateString: string,
  options?: {
    todayAsTime?: boolean;
    includeTime?: boolean;
    dateFormat?: string | null;
  }
) {
  const todayAsTime = options?.todayAsTime || false;
  const includeTime = options?.todayAsTime || false;
  const dateFormat = options?.dateFormat || MMDD;
  if (isToday(ISODateString)) {
    return todayAsTime ? renderDateOnlyTime(ISODateString) : 'Today';
  } else if (isTomorrow(ISODateString)) {
    return 'Tomorrow';
  } else if (isYesterday(ISODateString)) {
    return 'Yesterday';
  }
  return includeTime
    ? renderDateWithTime(ISODateString, dateFormat)
    : renderTextDate(ISODateString, dateFormat);
}

export function buildDuration(startsAt: string, endsAt: string) {
  const start = new Date(startsAt);
  const end = new Date(endsAt);
  const minutes = (end.valueOf() - start.valueOf()) / (60 * 1000);
  return {
    hours: (minutes - (minutes % 60)) / 60,
    minutes: minutes % 60,
  };
}

export function getTimeOfDayMs() {
  let now = Date.now();
  let midnight = new Date();
  midnight.setHours(0, 0, 0);
  return now - midnight.valueOf();
}

// Timeframe methods

function formatTimeframe(start: Date, end: Date) {
  return [start.toISOString(), end.toISOString()];
}

function formatEndDate(endDate: Date) {
  // Defaults to today if no date is provided
  return endDate ? new Date(endDate) : new Date();
}

export function getStartOfDay(ISODateString: string | number) {
  return startOfDay(new Date(ISODateString)).toISOString();
}

export function getEndOfDay(ISODateString: string | number) {
  return endOfDay(new Date(ISODateString)).toISOString();
}

export function getFullDay(ISODateString: string | number) {
  const date = new Date(ISODateString);
  return formatTimeframe(startOfDay(date), endOfDay(date));
}

export function getToday() {
  const date = new Date();
  return formatTimeframe(startOfDay(date), endOfDay(date));
}

export function getYesterday() {
  const date = subDays(new Date(), 1);
  return formatTimeframe(startOfDay(date), endOfDay(date));
}

export function getThisWeek(weekStartsOn: Day = 0) {
  const date = new Date();
  return formatTimeframe(
    startOfWeek(date, { weekStartsOn }),
    endOfWeek(date, { weekStartsOn })
  );
}

export function getLastWeek(weekStartsOn: Day = 0) {
  const date = subWeeks(new Date(), 1);
  return formatTimeframe(
    startOfWeek(date, { weekStartsOn }),
    endOfWeek(date, { weekStartsOn })
  );
}

export function getPastSevenDays(endDate: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfDay(subDays(date, 7)), endOfDay(date));
}

export function getMonthToDate(endDate: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfMonth(date), endOfDay(date));
}

export function getLastMonth(endDate: Date) {
  const date = subMonths(formatEndDate(endDate), 1);
  return formatTimeframe(startOfMonth(date), endOfMonth(date));
}

export function getPastThirtyDays(endDate: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfDay(subDays(date, 30)), endOfDay(date));
}

export function getPastNinetyDays(endDate: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfDay(subDays(date, 90)), endOfDay(date));
}

export function getYearToDate(endDate: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfYear(date), endOfDay(date));
}

export function getLastYear(endDate: Date) {
  const date = subYears(formatEndDate(endDate), 1);
  return formatTimeframe(startOfYear(date), endOfYear(date));
}

export function differenceInMinutes(
  startISODateString: string,
  endISODateString: string
) {
  return dateDifferenceInMinutes(
    parseISO(endISODateString),
    parseISO(startISODateString)
  );
}
