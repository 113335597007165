'use client';
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from 'react';
import type { Mesh } from 'three';

export type LoadedFile = Mesh | string;

type LoadedFilesValue = {
  loadedFiles: { [key: string]: LoadedFile };
  addLoadedFile: (id: number, file: LoadedFile) => void;
};

const LoadedFilesContext = createContext<LoadedFilesValue>({
  loadedFiles: {},
  addLoadedFile: () => null,
});

export default function LoadedFilesProvider({ children }: PropsWithChildren) {
  const [loadedFiles, setLoadedFiles] = useState<
    LoadedFilesValue['loadedFiles']
  >({});

  const handleAddLoadedFile = (id: number, file: LoadedFile) => {
    setLoadedFiles((f) => ({ ...f, [id]: file }));
  };

  const contextValue = {
    loadedFiles,
    addLoadedFile: handleAddLoadedFile,
  };

  return (
    <LoadedFilesContext.Provider value={contextValue}>
      {children}
    </LoadedFilesContext.Provider>
  );
}

export function useLoadedFiles() {
  const context = useContext(LoadedFilesContext);
  if (!context) {
    throw new Error('useLoadedFiles must be used within a LoadedFileProvider');
  }
  return context;
}
